import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPortalComponent } from './main-portal.component';
import { CommonComponentsModule } from '../../components/common-components.module';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        MainPortalComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        RouterModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class MainPortalModule {
}
