import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPortalComponent } from './view/main-portal/main-portal.component';
import { MainPortalRoutes } from './view/main-portal/main-portal-routing';

const routes: Routes = [
    {
        path: '',
        component: MainPortalComponent,
        children: MainPortalRoutes
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
