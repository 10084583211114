import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonComponentsModule } from './components/common-components.module';
import { MainPortalModule } from './view/main-portal/main-portal.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CommonComponentsModule,
        MainPortalModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private i18n: TranslateService) {
        i18n.setDefaultLang('cn');
        localStorage.setItem('lan', 'cn');
    }
}

export function TranslateLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
