import { Routes } from '@angular/router';

export const MainPortalRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(module => module.HomeModule)
    }
    // {
    //     path: 'calendar',
    //     loadChildren: () => import('./calendar/calendar.module').then(module => module.CalendarModule)
    // }
];
