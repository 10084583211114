import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-main-portal',
    templateUrl: './main-portal.component.html',
    styleUrls: ['./main-portal.component.scss']
})
export class MainPortalComponent implements OnInit {

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.currentLang = sessionStorage.getItem('lang') || 'cn';
    }

    opened: boolean = false;
    sidenavIcon = 'favorite_border';

    currentLang: string = '';

    changeLanguage(value: any) {
        this.currentLang = value;
        this.translate.use(value);
        sessionStorage.setItem('lang', value);
    }

    sidenavToggleButton() {
        this.opened = !this.opened;
        if (this.opened) {
            this.sidenavIcon = 'favorite';
        } else {
            this.sidenavIcon = 'favorite_border';
        }
    }
}
