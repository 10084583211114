<mat-toolbar class="toolbar">
    <span class="example-spacer"></span>
    <button mat-icon-button color="accent" (click)="sidenavToggleButton()">
        <mat-icon>{{sidenavIcon}}</mat-icon>
    </button>
    <button mat-icon-button color="accent">
        <mat-icon>share</mat-icon>
    </button>
    <button mat-icon-button color="accent" [matMenuTriggerFor]="menu">
        <mat-icon>translate</mat-icon>
    </button>
    <mat-menu #menu>
        <button mat-menu-item [ngClass]="{'language-selected' : currentLang === 'cn'}"
                (click)="changeLanguage('cn')">简体中文
        </button>
        <button mat-menu-item [ngClass]="{'language-selected' : currentLang === 'en'}"
                (click)="changeLanguage('en')">English
        </button>
    </mat-menu>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav position="end" mode="side" [(opened)]="opened" fixedInViewport="true" fixedTopGap="60">
        <mat-action-list>
            <mat-list-item>
                <span>{{ 'title' | translate}}</span>
            </mat-list-item>
        </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
